$(document).on('assigned-jobs-loaded', function(event) {
    const resetFilterButton = document.getElementById('resetFilterButton');
    const filterMenuButton = document.getElementById('filterMenuButton');
    const filterMenuPopup = document.getElementById('filterMenuPopup');
    const stackedOverlay = document.getElementById('stacked-overlay-buttons');
    const jobsFilter = document.getElementById('jobsFilter');

    if (stackedOverlay && jobsFilter && filterMenuButton) {
        stackedOverlay.appendChild(jobsFilter);

        const badgePulse = filterMenuButton.querySelector('.badge-pulse');
        const filterButtons = document.querySelectorAll('.job-type-filter');
        const priceButtons = document.querySelectorAll('.job-price-filter');
        const priceCounts = document.querySelectorAll('.count-with-prices');
        const jobInputs = document.querySelectorAll('.jobInput');
        const allButton = document.querySelector('.job-type-filter[data-job-type-slug="all"]');
        const selectedSlugs = new Set();
        let bonusFilterActive = false;
        let PaymentPrice = null;

        const updateLocalStorage = () => {
            const selectedFilters = {
                categories: Array.from(selectedSlugs),
                bonus: bonusFilterActive,
                price: PaymentPrice,
            };
            localStorage.setItem(`${gon.user}_cwJobsListFilter`, JSON.stringify(selectedFilters));
        };

        const toggleBadgePulse = () => {
            const hasFilters = selectedSlugs.size > 0 || bonusFilterActive || PaymentPrice !== null;
            badgePulse.classList.toggle('d-none', !hasFilters);
        };

        const updateResetButtonState = () => {
            const hasFilters = selectedSlugs.size > 0 || bonusFilterActive || PaymentPrice !== null;
            resetFilterButton.classList.toggle('disabled', !hasFilters);
        };

        const updatePriceCounts = () => {
            priceCounts.forEach(priceCount => {
                const threshold = parseFloat(priceCount.parentElement.dataset.paymentFilter);
                let count = 0;

                jobInputs.forEach(jobInput => {
                    const price = parseFloat(
                        jobInput.dataset.jobPayment?.replace(',', '.').replace('€', '').replace('$', '').trim() || 0
                    );
                    if (price >= threshold) count++;
                });

                const singularText = priceCount.dataset.priceTextSing;
                const pluralText = priceCount.dataset.priceTextMulti;
                priceCount.textContent = `${count} ${count === 1 ? singularText : pluralText}`;
            });
        };

        const applyFilters = () => {
            jobInputs.forEach(jobInput => {
                const jobSlug = jobInput.dataset.jobTypeSlug;
                const hasBonus = jobInput.classList.contains('bonus');
                const price = parseFloat(
                    jobInput.dataset.jobPayment?.replace(',', '.').replace('€', '').replace('$', '').trim() || 0
                );

                const matchesCategory = selectedSlugs.size === 0 || selectedSlugs.has(jobSlug);
                const matchesBonus = !bonusFilterActive || hasBonus;
                const matchesPrice = PaymentPrice === null || price >= PaymentPrice;

                if (matchesCategory && matchesBonus && matchesPrice) {
                    jobInput.style.display = 'flex';
                } else {
                    jobInput.style.display = 'none';
                }
            });

            updatePriceCounts();
            toggleBadgePulse();
            updateResetButtonState();
            updateLocalStorage();
        };

        const loadSavedFilters = () => {
            const savedFilters = JSON.parse(localStorage.getItem(`${gon.user}_cwJobsListFilter`));
            if (savedFilters) {
                if (savedFilters.categories && savedFilters.categories.length > 0) {
                    savedFilters.categories.forEach(slug => {
                        selectedSlugs.add(slug);
                        const button = document.querySelector(`.job-type-filter[data-job-type-slug="${slug}"]`);
                        if (button) button.classList.add('active');
                    });
                }

                if (savedFilters.bonus) {
                    bonusFilterActive = true;
                    const bonusButton = document.querySelector('.job-type-filter[data-job-type-slug="bonus"]');
                    if (bonusButton) bonusButton.classList.add('active');
                }

                if (savedFilters.price !== null) {
                    PaymentPrice = parseFloat(savedFilters.price);
                    const priceButton = document.querySelector(`.job-price-filter[data-payment-filter="${PaymentPrice.toFixed(1)}"]`);
                    if (priceButton) {
                        priceButton.classList.add('active');
                    }
                }

                applyFilters();
            }
        };

        filterMenuButton.addEventListener('click', () => {
            filterMenuPopup.classList.toggle('active');
            filterMenuButton.classList.toggle('active');
            resetFilterButton.classList.toggle('d-none');
        });

        resetFilterButton.addEventListener('click', () => {
            selectedSlugs.clear();
            bonusFilterActive = false;
            PaymentPrice = null;
            filterButtons.forEach(btn => btn.classList.remove('active'));
            priceButtons.forEach(btn => btn.classList.remove('active'));
            allButton.classList.add('disabled');
            applyFilters();
        });

        filterButtons.forEach(button => {
            button.addEventListener('click', () => {
                const selectedSlug = button.dataset.jobTypeSlug;

                if (selectedSlug === 'all') {
                    selectedSlugs.clear();
                    bonusFilterActive = false;
                    PaymentPrice = null;
                    filterButtons.forEach(btn => btn.classList.remove('active'));
                    priceButtons.forEach(btn => btn.classList.remove('active'));
                    allButton.classList.add('disabled');
                    applyFilters();
                } else if (selectedSlug === 'bonus') {
                    bonusFilterActive = !button.classList.contains('active');
                    button.classList.toggle('active');
                    allButton.classList.remove('disabled');
                    applyFilters();
                } else {
                    if (button.classList.contains('active')) {
                        selectedSlugs.delete(selectedSlug);
                        button.classList.remove('active');
                    } else {
                        selectedSlugs.add(selectedSlug);
                        button.classList.add('active');
                    }
                    allButton.classList.remove('disabled');
                    applyFilters();
                }
            });
        });

        priceButtons.forEach(button => {
            button.addEventListener('click', () => {
                const threshold = parseFloat(button.dataset.paymentFilter);

                if (PaymentPrice === threshold) {
                    PaymentPrice = null;
                    button.classList.remove('active');
                } else {
                    PaymentPrice = threshold;
                    priceButtons.forEach(btn => btn.classList.remove('active'));
                    button.classList.add('active');
                }

                applyFilters();
            });
        });
        loadSavedFilters();
        updatePriceCounts();
    }
});

$(document).on('load turbolinks:load', function() {
    $(document).on('click', '#logout-user-button', function() {
        localStorage.removeItem(`${gon.user}_cwJobsListFilter`);
    })
});
