import '../locale'
import './daterangepicker.min'
import './animated/animated-tabs'
import './contact/contact'
import './form/jquery.custom-file-input'
import './form/validation'
import './form/face_detection_drawer'
import './mail/mail'
import './default/app'
import './default/default'
import '../shared/date_range_input'
import './bootstrap-select-turbolinks-fix'
import './print-helper/printThis'
import './jtoggler'
import './EventedClass.coffee'
import './pages/profile.coffee'
import './form/form-wizard'
import './pages/phone_verification.js.coffee'
import './pages/jobs.coffee'
import './pages/payment_details.js.coffee'
import './pages/assestment_result.coffee'
import './pages/shorties'
import './pages/edit_job.coffee'
import './clickworker/profile_avatar.coffee'
import './clickworker/cw_richtext.coffee'
import './pages/validation-box'
import './form/ranking-element-helper' /* ranking df element */
import './pages/jobs-js-filter'

window.bootstrap = bootstrap;
const popoverTriggerList = document.querySelectorAll('[data-bs-toggle="popover"]')
const popoverList = [...popoverTriggerList].map(popoverTriggerEl => new bootstrap.Popover(popoverTriggerEl))
